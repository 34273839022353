import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';

import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';
import Superscript from '../../../../Elements/Superscript/Superscript';
import ComponentListRenderer from '../../../../Modules/ComponentListRenderer';
import Script from '../../../../Elements/Script';

import * as Styled from './HotNReadyPanel.style';

export interface IHotNReadyPanelProps {
  scriptText?: string;
  superscript?: string;
  bodyText?: string | string[];
  bottomImage: {
    alt?: string;
    url: string;
  };
}
const HotNReadyPanel: React.FC<IHotNReadyPanelProps> = ({ bottomImage, scriptText, superscript, bodyText }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
  return(
    <Flex sx={Styled.BoxImage}>
      <Box sx={Styled.Heading}>
        {scriptText && (
          <Script
            data-testid="ScriptText"
            sx=
              { 
                isRussiaSite ?
                  { ...Styled.ScriptText, transform: 'rotate(0deg) translateY(8px)' }
                  : Styled.ScriptText
              }
          >
            {scriptText}
            <Superscript> {superscript}</Superscript>
          </Script>
        )}
        {bodyText && (
          <Heading as='h1' sx={Styled.TeaserText}>
            <ComponentListRenderer listText={bodyText} />
          </Heading>
        )}
      </Box>
      {bottomImage && (
        <Box sx={Styled.ImageBox}>
          <Image src={bottomImage.url} sx={Styled.Image} />
        </Box>
      )}
    </Flex>
  );
};

export default HotNReadyPanel;
