import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';
import Image from '@lce/slice_v2/Elements/Image';

import { Direction } from '@lce/intl-types/src/IDirection';
import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';
import ComponentListRenderer from '../../../../Modules/ComponentListRenderer';
import Script from '../../../../Elements/Script';
import * as Styled from './BestValuePanel.style';
import Laurel from './assets/laurel.png';

export interface IBestValueProps {
  direction: Direction;
  awardText?: string | string[];
  scriptText?: string | string[];
  legalText?: string;
}

// eslint-disable-next-line max-lines-per-function
const BestValue: React.FC<IBestValueProps> = ({ awardText, direction, scriptText, legalText }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
  return(
    <Flex data-testid="BestValuePanel" sx={Styled.BestValue}>
      <Flex sx={Styled.BestValueCrest}>
        <Image 
          src={Laurel} 
          sx={{
            ...Styled.LeftLaurel,
            transform: direction === 'rtl' ? 'scaleX(-1)' : 'none',
          }} />
        <Box 
          sx= { 
            isRussiaSite ?
              {   
                ...Styled.LaurelText,
                fontSize: [ 16, 20, 24 ],
                lineHeight: '.9em',
              } : Styled.LaurelText
          }
        >
          {Array.isArray(awardText) ? (
            _map(awardText, (value, index) => {
              if (index === awardText.length - 1 || index === 0) {
                return (
                  <Box 
                    data-testid="ListComponent"
                    key={value}
                    sx= { isRussiaSite ?
                      { ...Styled.LaurelSmallText,   lineHeight: '1.2em' } 
                      : Styled.LaurelSmallText
                    }
                  >
                    {value}
                  </Box>
                );
              }
              return (
                <div data-testid="ListComponent" key={value}>
                  {value}
                </div>
              );
            })
          ) : (
            <div data-testid="SingleListComponent">{awardText}</div>
          )}
        </Box>
        <Image 
          src={Laurel} 
          sx={{
            ...Styled.RightLaurel,
            transform: direction === 'rtl' ? 'scaleX(1)' : 'scaleX(-1)',
          }} />
      </Flex>
      <Script sx={Styled.ValueScript}>
        {scriptText && <ComponentListRenderer listText={scriptText} />}
      </Script>
      <Box sx={Styled.LegalText}>{legalText}</Box>
    </Flex>
  );
};

export default BestValue;
