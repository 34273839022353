import * as React from 'react';
import Carousel from 'nuka-carousel';
import Box from '@lce/slice_v2/Layout/Box';

import { Direction } from '@lce/intl-types/src/IDirection';
import * as Styled from './LtoRotator.style';

export interface IRotatorProps {
  children: React.ReactElement | React.ReactNode;
  direction: Direction;
}

const Rotator: React.FC<IRotatorProps> = ({ children, direction }) => (
  <Box sx={Styled.LtoRotator}>
    <Box sx={{
      ...Styled.CustomCarousel,
      '.slider-frame': {
        direction: 'ltr',
        '.slider-list': {
          direction,
        },
      },
    }}>
      <Carousel
        autoplay={true}
        autoplayInterval={4000}
        pauseOnHover={false}
        withoutControls={false}
        wrapAround={true}
      >
        {children}
      </Carousel>
    </Box>
  </Box>
);

export default Rotator;
