import { SxStyleProp } from 'theme-ui';

export const LtoRotator: SxStyleProp = {
  bg: 'primary',
};

export const CustomCarousel: SxStyleProp = {
  margin: 'auto',
  display: 'flex',
  alignContent: 'flex-end',
  '.slider-control-centerleft': {
    display: 'none !important',
  },
  '.slider-control-centerright': {
    display: 'none !important',
  },
  '.slider-control-bottomcenter > ul > li': {
    p: '0 8px',
  },
  '.slider-control-bottomcenter > ul > li > button > svg > circle': {
    fill: '#fff !important',
  },
};
