import { SxStyleProp } from 'theme-ui';

export const BoxImage: SxStyleProp = {
  backgroundColor: 'primary',
  textAlign: 'center',
  flexDirection: 'column',
  mb: '24px',
  width: ['100%', '100%', '32.0516%'],
};

export const Heading: SxStyleProp = {
  flex: '1 1 auto',
  justifyContent: 'center',
  alignItems: 'center',
  mt: '24px',
  fontSize: [18, 32, 22],
};

export const TeaserText: SxStyleProp = {
  fontFamily: 'primary',
  fontWeight: 'bold',
  color: 'white',
  position: 'relative',
  zIndex: '1',
  m: '0 12px 20px 12px',
  lineHeight: '32px',
  textAlign: 'center',
  fontSize: 16,
};

export const ScriptText: SxStyleProp = {
  fontSize: '0.8em',
  lineHeight: '1em',
  transform: 'rotate(-3deg) translateY(8px)',
};

export const ImageBox: SxStyleProp = {
  padding: '0 16px',
  width: '100%',
};

export const Image: SxStyleProp = {
  verticalAlign: 'middle',
  width: '50%',
  height: 'auto',
  minHeight: '1px',
  mb: '0',
};
