import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';
import localeFilter from '@lce/intl-util/src/localeFilter';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { IBestValueProps } from '@lce/intl-ui/src/PageComponents/Home/Panels/BestValuePanel';
import { Direction } from '@lce/intl-types/src/IDirection';

import useSiteConfig from '../Config/useSiteConfig';

interface INodeBestValuePanelProps {
  bestValuePanel: {
    nodes: IDatoBestValueNodeProps[];
  };
}

interface IDatoBestValueNodeProps {
  locale: string;
  awardText?: ILineObject[];
  scriptText?: ILineObject[];
  legalText?: string;
}

const useBestValuePanel = (locale: string): IBestValueProps => {
  const data: INodeBestValuePanelProps = useStaticQuery(graphql`
    query UseBestValuePanelQuery {
      bestValuePanel: allDatoCmsBestValuePanel {
        nodes {
          locale
          awardText {
            line
          }
          scriptText {
            line
          }
          legalText
        }
      }
    }
  `);
  const { siteConfig } = useSiteConfig(locale);
  const direction: Direction = siteConfig.isRtl ? 'rtl' : 'ltr';


  const removeLine = (array?: ILineObject[]): string[] => _map(array, object => object.line);

  const bestValuePanel = localeFilter<IDatoBestValueNodeProps>(data.bestValuePanel.nodes, locale);

  return {
    ...bestValuePanel,
    direction,
    awardText: bestValuePanel && removeLine(bestValuePanel.awardText),
    scriptText: bestValuePanel && removeLine(bestValuePanel.scriptText),
  };
};

export default useBestValuePanel;
