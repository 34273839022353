import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';
import { IActiveBannerProps } from '@lce/intl-types/src/IActiveBannerProps';
import DefaultBanner from '@lce/intl-ui/src/Patterns/Banners/DefaultBanner';

import RotatorLoader from '@lce/intl-ui/src/Modules/RotatorLoader/RotatorLoader';
import { Direction } from '@lce/intl-types/src/IDirection';
import UseBanner from '../../Patterns/Banners/useBanner';
import { sanitizedList } from './sanitizedList';

export interface IBannerLoaderProps {
  locale: string;
  direction: Direction;
  MainBanner?: React.FC<any>;
  bannerData: IActiveBannerProps[];
}

const BannerLoader: React.FC<IBannerLoaderProps> = ({ bannerData, direction, MainBanner = DefaultBanner, ...props }) => {
  const listData = sanitizedList(bannerData);
  return (
    <RotatorLoader bannerList={listData} direction={direction}>
      {_map(listData, (banner: IActiveBannerProps) => {
        const LoadedBanner = banner.customBannerComponent ? UseBanner[banner.customBannerComponent] : MainBanner;
        const testId = banner.customBannerComponent || 'BannerComponent';

        return (
          <Box data-testid={testId} key={banner.id}>
            <LoadedBanner {...props} {...banner.activeBanner} direction={banner.direction} />
          </Box>
        );
      })}
    </RotatorLoader>
  );
};

export default BannerLoader;
