import * as React from 'react';
//Import custom banners here and add them to the Banners object.

export interface IObjectIndexer<T> {
  [key: string]: T;
}

const Banners: IObjectIndexer<React.FC<any>> = {};

export default Banners;
