import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';
import PanelLoader from '@lce/intl-ui/src/PageComponents/Home/PanelLoader';
import DefaultBanner from '@lce/intl-ui/src/Patterns/Banners/DefaultBanner';

import BannerLoader from '../ui/Modules/BannerLoader';
import Layout from '../ui/ConnectedLayout';
import useActiveBanner from '../api/HomePage/useActiveBanner';
import useSiteConfig from '../api/Config/useSiteConfig';
import useBestValuePanel from '../api/HomePage/useBestValuePanel';
import useImageTopPanel from '../api/HomePage/useImageTopPanel';
import useHotNReadyPanel from '../api/HomePage/useHotNReadyPanel';
import useQualityPanel from '../api/HomePage/useQualityPanel';

const Home: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;

  const bannerData = useActiveBanner(locale);
  const hotNReadyPanel = useHotNReadyPanel(locale);
  const imageTopPanel = useImageTopPanel(locale);
  const bestValuePanel = useBestValuePanel(locale);
  const qualityPanel = useQualityPanel(locale);
  const { direction } = useSiteConfig(locale);
  
  const panelProps = { hotNReadyPanel, imageTopPanel, bestValuePanel, qualityPanel, direction };
  const isThreePanelDefault = false; // This value comes from siteConfig.isThreePanel

  return (
    <Layout {...props} >
      <Container sx={{ px: '16px' }}>
        <Box sx={{ backgroundColor: 'primary', mb: '24px' }}>
          <BannerLoader bannerData={bannerData} direction={direction} locale={locale} MainBanner={DefaultBanner} />
        </Box>
        <PanelLoader {...panelProps} isThreePanel={isThreePanelDefault} />
      </Container>
    </Layout>
  );
};

export default Home;
