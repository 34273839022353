import { SxStyleProp } from 'theme-ui';

export const Lto: SxStyleProp = {
  width: '100%',
  bg: 'primary',
  flexDirection: ['column-reverse', 'row'],
  overflow: 'hidden',
  minHeight: '404px',
};

export const LtoImageBlock: SxStyleProp = {
  flex: ['1 1 auto', '1 1 70%'],
  position: 'relative',
  overflow: 'hidden',
  justifyContent: 'center',
  alignSelf: 'center',
  pt: '20px',
  pl: '24px',
};

export const LtoImage: SxStyleProp = {
  width: '100%',
  height: 'auto',
  maxWidth: '800px',
  minHeight: '1px',
  m: '0',
};

export const LtoMessageBlock: SxStyleProp = {
  alignItems: 'center',
  flex: ['1 1 auto', '1 1 30%'],
  p: ['16px 16px 0 16px', '16px'],
  minHeight: ['150px', ''],
  minWidth: ['unset', '400px'],
};

export const LtoText: SxStyleProp = {
  fontFamily: 'primary',
  fontWeight: 'bold',
  lineHeight: '0.8em',
  whiteSpace: 'pre-line',
  pl: ['15px', '0'],
  pb: ['0', '32px'],
  mb: '0',
  fontSize: [26, 32],
  width: '100%',
};
