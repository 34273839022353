import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, sortBy as _sortBy } from 'lodash';
import { Direction } from '@lce/intl-types/src/IDirection';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { localeFilterList } from '@lce/intl-util/src/localeFilter';
import { IActiveBannerProps } from '@lce/intl-types/src/IActiveBannerProps';

import useSiteConfig from '../Config/useSiteConfig';

interface IDatoActiveBannerProps {
  activeBanners: {
    nodes: IDatoNodeActiveBannerProps[];
  };
}

export interface IDatoNodeActiveBannerProps {
  id: string;
  locale: string;
  endTime?: string;
  startTime?: string;
  customBannerComponent?: string;
  position: number;
  activeBanner: IDatoHomePageDefaultProps[];
  direction: Direction;
}

interface IDatoHomePageDefaultProps {
  id: string;
  bannerName: string;
  image: IDatoImage;
  cents?: string;
  subCents?: string;
  dollars?: string;
  currencySymbol?: IDatoImage;
  subTitle: string;
  script?: string;
  superScript?: string;
  title: ILineObject[];
}

// eslint-disable-next-line max-lines-per-function
const useActiveBanner = (locale: string) => {
  const data: IDatoActiveBannerProps = useStaticQuery(graphql`
    query ActiveBannerQuery {
      activeBanners: allDatoCmsActiveBanner {
        nodes {
          id
          locale
          startTime
          endTime
          activeBanner {
            bannerName
            cents
            dollars
            currencySymbol {
              alt
              url
            }
            image {
              alt
              url
            }
            script
            subCents
            subTitle
            superScript
            title {
              line
            }
          }
          customBannerComponent
          position
        }
      }
    }
  `);
  const { siteConfig } = useSiteConfig(locale);
  const direction: Direction = siteConfig.isRtl ? 'rtl' : 'ltr';

  const removeLine = (array?: ILineObject[]): string[] => _map(array, object => object.line);

  const filteredOffsetBanners = localeFilterList<IDatoNodeActiveBannerProps>(data.activeBanners.nodes, locale);

  let cleanedActiveBanners: IActiveBannerProps[] = [];

  _map(filteredOffsetBanners, offsetBanner => {
    if (offsetBanner.activeBanner[0]) {
      return (cleanedActiveBanners = [
        ...cleanedActiveBanners,
        {
          ...offsetBanner,
          direction,
          activeBanner: {
            ...offsetBanner.activeBanner[0],
            title: removeLine(offsetBanner.activeBanner[0].title),
          },
        },
      ]);
    }

    return (cleanedActiveBanners = [
      ...cleanedActiveBanners,
      {
        ...offsetBanner,
      },
    ]);
  });

  const sortedActiveBanner = _sortBy(cleanedActiveBanners, (banners) => banners.position);

  return {
    ...sortedActiveBanner,
  };
};

export default useActiveBanner;
