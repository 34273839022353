import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { Direction } from '@lce/intl-types/src/IDirection';

import LtoPrice from './components/LtoPrice';
import BannerTitle from './components/BannerTitle';
import * as Styled from './DefaultBanner.style';

export interface IDefaultBannerProps {
  bannerName?: string;
  cents?: string;
  currencySymbol?: IDatoImage;
  dollars?: string;
  image?: IDatoImage;
  locale: string;
  script?: string;
  subCents?: string;
  subTitle?: string;
  subTitle2?: string;
  superScript?: string;
  title?: string[];
  direction: Direction;
}

const DefaultBanner: React.FC<IDefaultBannerProps> = ({
  image,
  cents,
  currencySymbol,
  subCents,
  subTitle,
  subTitle2,
  dollars,
  title,
  script,
  superScript,
  direction,
  locale,
}) => (
  <Flex sx={Styled.Lto}>
    <Box sx={Styled.LtoImageBlock}>
      {image && image.url &&
        <Image alt="" src={image.url} sx={Styled.LtoImage} />
      }
    </Box>
    <Flex sx={locale === 'ru-RU' ? { ...Styled.LtoMessageBlock, fontSize: [26, 32] } : Styled.LtoMessageBlock}>
      <Box sx={locale === 'ru-RU' ? { ...Styled.LtoText, fontSize: [20, 26] } :  Styled.LtoText}>
        <LtoPrice cents={cents} currencySymbol={currencySymbol} direction={direction} dollars={dollars} subCents={subCents} />
        {title && <BannerTitle script={script} subTitle={subTitle} subTitle2={subTitle2} superScript={superScript} title={title} />}
      </Box>
    </Flex>
  </Flex>
);

export default DefaultBanner;
