import * as React from 'react';
import { size as _size } from 'lodash';

import { Direction } from '@lce/intl-types/src/IDirection';
import LtoRotator from '../LtoRotator';

export interface IRotatorLoaderProps {
  bannerList: any[] | any;
  direction: Direction;
}

const RotatorLoader: React.FC<IRotatorLoaderProps> = ({ children, bannerList, direction }) => {
  if (_size(bannerList) > 1) {
    return <LtoRotator direction={direction}>{children}</LtoRotator>;
  }
  return <>{children}</>;
};

export default RotatorLoader;
