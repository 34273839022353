import { SxStyleProp } from 'theme-ui';

export const BestValue: SxStyleProp = {
  backgroundColor: 'primary',
  p: '28px 16px 8px 16px',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  mb: '24px',
  width: ['100%', '100%', '32.0516%'],
};

export const BestValueCrest: SxStyleProp = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  mb: '12px',
  direction: 'ltr',
};

export const LeftLaurel: SxStyleProp = {
  width: ['44px', '62px', '76px'],
  mr: '8px',
};

export const RightLaurel: SxStyleProp = {
  width: ['44px', '62px', '76px'],
  ml: '8px',
};

export const LaurelText: SxStyleProp = {
  fontFamily: 'primary',
  fontWeight: 'bold',
  fontSize: [ 20, 24, 32],
  color: 'white',
  position: 'relative',
  zIndex: '1',
  lineHeight: '0.7em',
  textAlign: 'center',
  m: ['0 -8px', '0 -12px', '0 -16px'],
};

export const LaurelSmallText: SxStyleProp = {
  fontSize: '0.5em',
  lineHeight: '0.8em',
};

export const ValueScript: SxStyleProp = {
  color: 'black',
  fontWeight: 'normal',
  fontSize: 20,
  lineHeight: '1em',
  my: '1.5rem',
};

export const LegalText: SxStyleProp = {
  fontFamily: 'tertiary',
  maxWidth: '480px',
  m: '16px auto 0 auto',
  fontSize: 6,
  lineHeight: '1em',
};
