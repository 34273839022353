import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import { Direction } from '@lce/intl-types/src/IDirection';

import ImageTopPanel, { IImageTopPanelProps } from '../Panels/ImageTopPanel';
import HotNReadyPanel, { IHotNReadyPanelProps } from '../Panels/HotNReadyPanel';
import BestValuePanel, { IBestValueProps } from '../Panels/BestValuePanel';
import QualityPanel, { IQualityPanelSectionProps } from '../Panels/QualityPanel';

interface IPanelLoaderProps {
  isThreePanel: boolean;
  direction: Direction;
  hotNReadyPanel: IHotNReadyPanelProps;
  imageTopPanel: IImageTopPanelProps;
  bestValuePanel: IBestValueProps;
  qualityPanel: IQualityPanelSectionProps[];
}

const PanelLoader: React.FC<IPanelLoaderProps> = ({
  isThreePanel,
  direction, 
  hotNReadyPanel, 
  imageTopPanel,
  bestValuePanel,
  qualityPanel }) => {

  const Default3Panel: React.FC = () => (
    <>
      <BestValuePanel {...bestValuePanel}  direction={direction} />
      <ImageTopPanel {...imageTopPanel} />
      <HotNReadyPanel {...hotNReadyPanel} />
    </>
  );
  const Default2Panel: React.FC = () => (
    <>
      <QualityPanel sections={qualityPanel} />
      <HotNReadyPanel {...hotNReadyPanel} />
    </>
  );
  const LoadedComponents: React.FC = isThreePanel ? Default3Panel : Default2Panel;

  return (
    <Flex
      data-testid="PanelLayout"
      sx={{
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
      }}
    >
      <LoadedComponents />
    </Flex>
  );
};

export default PanelLoader;
