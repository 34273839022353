import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import Flex from '@lce/slice_v2/Layout/Flex';

import ComponentListRenderer from '../../../../Modules/ComponentListRenderer';
import Script from '../../../../Elements/Script';

import * as Styled from './ImageTopPanel.style';

export interface IImageTopPanelProps {
  headline: string | string[];
  script?: string | string[];
  image?: {
    alt?: string;
    url?: string;
  };
}

const BoxImage: React.FC<IImageTopPanelProps> = ({ headline, script, image }) => (
  <Flex sx={Styled.BoxImage}>
    {image && (
      <Box sx={Styled.ImageBox}>
        <Image src={image.url} sx={Styled.Image} />
      </Box>
    )}
    <Heading sx={Styled.Heading}>
      {script &&
        <Script sx={Styled.TitleScript}>
          <ComponentListRenderer listText={script} />
        </Script>}
      <Box sx={Styled.HeadlineText}><ComponentListRenderer listText={headline} /></Box>
    </Heading>
  </Flex>
);

export default BoxImage;
