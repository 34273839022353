import { graphql, useStaticQuery } from 'gatsby';

import localeFilter from '@lce/intl-util/src/localeFilter';
import { IHotNReadyPanelProps } from '@lce/intl-ui/src/PageComponents/Home/Panels/HotNReadyPanel';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import removeLine from '@lce/intl-util/src/removeLine';

interface INodeHotNReadyPanelProps {
  HotNReadyPanel: {
    nodes: IDatoHotNReadyNodeProps[];
  };
}

interface IDatoHotNReadyNodeProps {
  locale: string;
  scriptText?: string;
  superscript?: string;
  bodyText?: ILineObject[];
  bottomImage: {
    alt?: string;
    url: string;
  };
}

const useHotNReady = (locale: string): IHotNReadyPanelProps => {
  const data: INodeHotNReadyPanelProps = useStaticQuery(graphql`
    query UseHotNReadyPanelQuery {
      HotNReadyPanel: allDatoCmsHotNReadyPanel {
        nodes {
          locale
          scriptText
          superscript
          bodyText {
            line
          }
          bottomImage {
            url
          }
        }
      }
    }
  `);

  const HotNReadyPanel = localeFilter<IDatoHotNReadyNodeProps>(data.HotNReadyPanel.nodes, locale);

  return {
    ...HotNReadyPanel,
    scriptText: HotNReadyPanel && HotNReadyPanel.scriptText,
    superscript: HotNReadyPanel && HotNReadyPanel.superscript,
    bodyText: HotNReadyPanel && removeLine(HotNReadyPanel.bodyText),
    bottomImage: HotNReadyPanel && HotNReadyPanel.bottomImage,
  };
};

export default useHotNReady;
