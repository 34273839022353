import { SxStyleProp } from 'theme-ui';

export const BoxImage: SxStyleProp = {
  backgroundColor: 'primary',
  textAlign: 'center',
  flexDirection: 'column',
  mb: '24px',
  minHeight: '1px',
  width: ['100%', '100%', '32.0516%'],
};

export const Heading: SxStyleProp = {
  fontSize: [24, 40, 26],
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 0 -16px 0',
};

export const HeadlineText: SxStyleProp = {
  color: 'white',
  p: '0 16px 28px 16px',
  maxWidth: '480px',
  position: 'relative',
  textTransform: 'uppercase',
  lineHeight: '.8em',
  zIndex: '1',
  m: '0 auto',
  width: '100%',
  transform: ['translateX(-3px) translateY(-50px)', 'translateX(-3px) translateY(-40px)'],
};

export const TitleScript: SxStyleProp = {
  position: 'relative',
  zIndex: '1',
  lineHeight: '1em',
  fontSize: '0.9em',
  fontWeight: 'normal',
  transform: ['rotate(-3deg) translatex(-5px) translateY(-40px)', 'rotate(-3deg) translateY(-30px)'],
};

export const ImageBox = {
  minHeight: '1px',
  flex: '1 1 auto',
  display: 'block',
};

export const Image = {
  verticalAlign: 'middle',
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  m: ['0 0 -48px 0', '0 0 -72px 0'],
};
